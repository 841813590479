// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cipe-how-to-js": () => import("./../../../src/pages/cipeHowTo.js" /* webpackChunkName: "component---src-pages-cipe-how-to-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-app-js": () => import("./../../../src/pages/installApp.js" /* webpackChunkName: "component---src-pages-install-app-js" */),
  "component---src-pages-pharmacy-js": () => import("./../../../src/pages/pharmacy.js" /* webpackChunkName: "component---src-pages-pharmacy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sms-code-app-cipe-js": () => import("./../../../src/pages/smsCodeApp/cipe.js" /* webpackChunkName: "component---src-pages-sms-code-app-cipe-js" */),
  "component---src-pages-sms-code-app-dni-js": () => import("./../../../src/pages/smsCodeApp/dni.js" /* webpackChunkName: "component---src-pages-sms-code-app-dni-js" */),
  "component---src-pages-sms-code-app-js": () => import("./../../../src/pages/smsCodeApp.js" /* webpackChunkName: "component---src-pages-sms-code-app-js" */),
  "component---src-pages-technical-support-js": () => import("./../../../src/pages/technicalSupport.js" /* webpackChunkName: "component---src-pages-technical-support-js" */),
  "component---src-pages-technical-support-mac-config-js": () => import("./../../../src/pages/technicalSupport/mac-config.js" /* webpackChunkName: "component---src-pages-technical-support-mac-config-js" */),
  "component---src-pages-technical-support-windows-config-js": () => import("./../../../src/pages/technicalSupport/windows-config.js" /* webpackChunkName: "component---src-pages-technical-support-windows-config-js" */),
  "component---src-templates-category-procedure-js": () => import("./../../../src/templates/categoryProcedure.js" /* webpackChunkName: "component---src-templates-category-procedure-js" */),
  "component---src-templates-procedure-js": () => import("./../../../src/templates/procedure.js" /* webpackChunkName: "component---src-templates-procedure-js" */)
}

